#fixed-bar {
  top: 0;
  z-index: 20;
  position: fixed;
  width:100%;
  opacity: 0;
  max-height: 0;
  -webkit-transition: opacity 750ms ease-in-out, background-color 750ms ease-in-out;
  -moz-transition: opacity 750ms ease-in-out, background-color 750ms ease-in-out;
  transition: opacity 750ms ease-in-out, background-color 750ms ease-in-out;

  .bgforfade {
    background-color: rgba(#444, 0.9);
  }

  &.notfixed {
    pointer-events: none;
  }

  &.topfixed {
    height: auto;
    max-height: 281px !important;
    opacity: 1 !important;
    display: block;
  }

  .logo {
    margin: auto;
    display: block;
    padding: 0 0 10px;
    max-width: 180px;
  }

  .row {
    padding: 20px 0 0;
  }

  .bookingwidget {
    max-height: 150px;

    @media (min-width: 1200px) {
      max-height: 100px;
    }
  }
}