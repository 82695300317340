header {
  #headertopbar {
    padding: 5px 0;
    border-bottom: 1px solid #444;

    .leftbar a {
      text-decoration: none;

      display: flex;
      flex-direction: row;
      justify-content: center;

      p,
      svg {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      svg {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }

      p {
        color: $primary-color;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
      }
    }

    .rightbar {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .locationtext {
        margin-left: 5px;
        font-style: italic;
        font-weight: 400;
      }

      svg,
      p {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      svg {
        width: 15px;
        height: 33px;
        margin-right: 5px;
      }

      p {
        font-size: 16px;
        text-align: center;
        font-weight: 600;
      }
    }

    p {
      margin-bottom: 0;
    }

    svg {
      fill: $primary-color;
    }
  }

  #headerbottombar {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .col-12 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .logo {
      width: auto;
      max-height: 50px;
      margin: 10px auto;
      display: block;
    }
  }
}