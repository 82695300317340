#midbar.facebook-midbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;

  h3,
  svg {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  svg {
    margin-left: 15px;
  }
}