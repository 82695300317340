iframe {
  border: none;
  width: 100%;
}

a {
  margin: auto;
}

h2 {
  color: $primary-color;
}

h3 {
  font-size: 1.35em;
}

.booking-tool {
  background: $primary-color;
  border-radius: 5px;
  padding: 10px;

  h2,
  h3,
  p {
    color: #fff;
    text-align: center;
  }
}

.bg-primary {
  background: $primary-color !important;

  h3 {
    color: #fff;
  }

  svg {
    background: #fff;
    border-radius: 50%;
    fill: $primary-color;
    height: 50px;
    padding: 5px;
    width: 50px;
  }
}

.bg-grey {
  background: #eee;
}

.bmg-review-widget.large .review-widget {
  margin: 10px auto auto;
  width: 100%;
  max-width: 350px;

  .review-widget-wrapper .main-content .green {
    color: #0b8010 !important;
  }
}

.c1 {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: auto auto 20px;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;

  &:hover {
    background: $primary-color;

    svg {
      fill: #fff;
    }
  }

  svg {
    fill: #999;
    height: 40px;
    width: 40px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

#topbanner {
  padding: 40px 0;

  #bannertext {
    h1,
    h2,
    p {
      color: #fff;
    }

    h1 {
      font-size: 60px;
      font-weight: 600;

      @media (max-width: 767px) {
        font-size: 30px;
      }
    }

    p {
      font-size: 22px;
    }
  }
}

#midbar.booking-midbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;

  .col-12 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .btn {
    background: #fff;
    border: 1px solid #fff;
    color: $primary-color;
    font-weight: 600;
    padding: 8px 25px;
    width: 100%;

    &:hover {
      background: $primary-color;
      color: #fff;
      text-decoration: none;
    }
  }
}

#howitworks {
  @media (max-width: 767px) {
    .col-12 {
      margin-bottom: 10px;
    }
  }

  h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

#contactdetails {
  h3,
  p,
  b {
    text-align: center;
  }
}

#bookonline-content {
  h3 {
    color: $primary-color;
    margin-bottom: 20px;
  }
}

.cover {
  width:100%;
  height: 100%;
  border-radius: 0px;
  cursor: pointer;
}

.playbtn {
  height: 60px;
  cursor: pointer;
  opacity: 0.9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d( -50%, -50%, 0 );
}