footer {
  background: #333;

  #map {
    width: 100%;
    height: 300px;
    border:none;
    z-index: 1;
  }

  svg {
    display: block;
    fill: #eee;
    margin: auto auto 10px;
    height: 30px;
    width: 30px;
  }

  h3,
  p,
  a,
  a:hover {
    color: #eee;
    text-align: center;
  }


  #footercontactinfo {
    @media (max-width: 767px) {
      .col-12 {
        margin-bottom: 20px;
      }
    }

    h3 {
      font-size: 22px;
      margin-top: 5px;
    }

    p {
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  #copyrightbox {
    border-top: 1px solid #eee;
    padding: 5px 0;

    p {
      margin-bottom: 0;
    }
  }
}